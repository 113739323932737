import React from "react";
import styles from "./styles.module.scss";
import CommunityLinks from "../Links/CommunityLinks";
import { SentinelLogo } from "../../assets/logos";
import SocialLinks from "../Links/SocialLinks";
import { tncLinks } from "../../constants/links.constants";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <section className={styles.footerTop}>
        <section className={styles.footerTopLeft}>
          <SentinelLogo />
          <SocialLinks />
        </section>
        <section className={styles.footerTopRight}>
          <CommunityLinks />
        </section>
      </section>
      <section className={styles.footerBottom}>
        <section className={styles.footerBottomLeft}>
          <span>&copy; 2023-{`${new Date().getFullYear()}`} Sentinel. All rights reserved.</span>
        </section>
        <section className={styles.footerBottomRight}>
          {tncLinks.map(({ title, href }) => (
            <a
              key={title}
              href={href}
              className={styles.footerBottomRightLink}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {title}
            </a>
          ))}
        </section>
      </section>
    </div>
  );
};

export default Footer;
